import serviceapi from '@/api/api'

export function getUserInfo() {
    return serviceapi.post("/v1/user/info")
}

export function getMenus() {
    return serviceapi.post("/v1/user/menu/list")
}

export function getClientUser() {
    return serviceapi.post("/v1/device/user/info")
}

export function getMyCompany() {
    return serviceapi.post("/v1/device/company/info")
}