import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store/index'
import loginAgent from '@/components/loginAgent.js'
import { getToken, removeToken } from '@/utils/auth'

import router from "@/router/index"

const serviceapi = axios.create({
    baseURL: "/wrapi",
    timeout: 20000,  // 超时设置 单位 ms，
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

serviceapi.interceptors.request.use(config => {
   //  console.log('axios 请求拦截')
   config.headers['Authentication'] =  getToken() // 请求头带上token
   let lang = store.getters.getLanguageHeader
   config.headers['Accept-Language'] = lang // 设置语言头
   return config
})

serviceapi.interceptors.response.use(response => {
      let res = response.data;
      if (res.code === 200) {
         return Promise.resolve(response.data)
      }else if (res.code === 401) {
         removeToken()
         goToLoginPage()
      }  else if (res.code === 407) {
         loginAgent()
         return Promise.reject(response.data.msg)
      } else {
          ElMessageBox.alert(res.msg ? res.msg : '系统异常！', '请求出错', {
              confirmButtonText: 'OK'
          })
         return Promise.reject(response.data.msg)
      }
   },
   error => {
      console.log(error)
      if(error.response.data) {
         error.message = error.response.data.msg
      }
      if (error.response.status === 401) {

         goToLoginPage()
      }
      if(error.response.status === 407) {
         loginAgent()
      }
      ElMessageBox.alert(error.message, {duration: 3 * 1000})
      return Promise.reject(error)
   }
)

const goToLoginPage = () => {
   // console.log('router', router.currentRoute.value)
   let path = router.currentRoute.value
   let deviceReg = /^\/device\/.*/
   if (deviceReg.test(path)) {
      console.log('go to loing device')
      router.push({ name: 'ClientLogin' })
      return;
   }
   // router.push({ name: 'Platform' })
   return;
}

export default serviceapi