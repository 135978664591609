import store from "@/store/index"

const whiteList = ['/', '/home', '/login', '/wrlogin', '/device', '/device/home', '/device/login', '/agent/login']

const loginPath = ['/login', '/wrlogin', '/device/login']

const isWhiteRoute = (routePath) => {
    if (whiteList.indexOf(routePath) !== -1) {
        return true
    }
    return false
}

const isLoginPath = (toPath) => {
    if (loginPath.indexOf(toPath) !== -1) {
        return true
    }
    return false
}

const generaetRoutes = () => {

}

export {
    isWhiteRoute,
    isLoginPath
}