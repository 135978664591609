import { createRouter, createWebHashHistory } from 'vue-router'
import {isAuthenticated} from '@/utils/auth'
import { isLoginPath, isWhiteRoute } from '../utils/permission'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: ()=> import('@/views/Layout.vue'),
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/error/NotFound.vue') },
  { path: '/login', name: 'Platform', component: () => import('@/views/PlatformLogin.vue') },
  // { path: '/wrlogin', name: 'Login', component: () => import('@/views/Login.vue') },

  // {
  //   path: '/device',
  //   name: 'ClientIndex',
  //   redirect: '/device/home',
  //   component: ()=> import('@/views/UserLayout.vue'),
  //   children: [{
  //     path: '/device/home',
  //     name: 'ClientHome',
  //     component: () => import('@/views/device/ClientHome.vue')
  //   },
  //   {
  //     path: '/device/groups',
  //     name: 'MyGroups',
  //     component: () => import('@/views/device/DeviceGroups.vue')
  //     },
  //   {
  //     path: '/device/login',
  //     name: 'ClientLogin',
  //     component: () => import('@/views/device/ClientLogin.vue')
  //     },
  //     { path: '/device/signup', name: 'SignUpCompany', component: () => import('@/views/device/SignupCompany.vue') },
  //     { path: '/groups/join', name: 'UserJoinGroup', component: () => import('@/views/device/SignupCompany.vue') },

  //   ]
  // },
  // { path: '/agent/login', name: 'AgentLogin', component: () => import('@/views/device/CommonLayout.vue') },
  // {
  //   path: '/agent',
  //   name: 'AgentIndex',
  //   redirect: '/wrlogin',
  //   component: () => import('@/views/agent/AgentLayout.vue'),
  //   children: [{
  //     path: '/agent/devices/manage',
  //     name: 'AgentDeviceManage',
  //     component: () => import('@/views/agent/DevicesManagement.vue')
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  if (!isAuthenticated()) {
    console.log(to.fullPath)
    if (isLoginPath(to.path) || isWhiteRoute(to.path)) {
      return true
    } else {
      console.log('user not logined')
      return goUserLoinHome(to.path)
    }
  } else {
    console.log('user logined', to.name, router.getRoutes())
    return true
  }
})

const goUserLoinHome = (path) => {
  let deviceReg = /^\/device\/.*/
  if (deviceReg.test(path)) {
    console.log('go to loging device')
    return { name: 'ClientLogin' };
  }
  return { name: 'AgentLogin' };
}


router.afterEach(() => {


})

export default router
